import { useState } from "react";
import { Link } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Api from "api";
import LoadingSpinner from "components/Common/LoadingSpinner";
import {
  Button,
  message,
  Space,
  Avatar,
  Tooltip,
  Tag,
  Row,
  Col,
  notification,
} from "antd";
import { DownloadOutlined, EyeOutlined, SendOutlined } from "@ant-design/icons";
import {
  DeliverableDocumentNode,
  GetUniqueDocumentsQuery,
  GetDefinitionQuery,
  DsUserNode,
} from "types";
import SmartTable from "components/Common/Tables";
import { ColumnsType } from "antd/es/table";
import SubmissionForm from "./SubmissionForm";

type CustomDeliverableDocument = DeliverableDocumentNode & {
  outdated?: boolean;
};

interface Props {
  searchText: string;
  applicationId?: string;
}

const DraftApplicationDocuments: React.FC<Props> = (props) => {
  const { searchText, applicationId } = props;
  const [documents, setDocuments] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(true);
  const [submissionDocument, setSubmissionDocument] = useState<DeliverableDocumentNode | null>(
    null,
  );

  const { loading } = useQuery<GetUniqueDocumentsQuery>(Api.Document.GetUniqueDocuments(), {
    variables: { applicationId: applicationId },
    onCompleted: (data) => {
      const docs = data?.uniqueDeliverableDocuments;
      const documents = [...docs].map(({ current, oldVersions }) => {
        if (oldVersions.length < 1) return current;
        const versions = oldVersions.map((d) => ({ ...d, outdated: true }));
        return {
          ...current,
          children: versions,
        };
      });
      setDocuments(documents.sort((a, b) => (a.name < b.name ? -1 : 1)));
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const [getAnchors, { data: definition }] = useLazyQuery<GetDefinitionQuery>(
    Api.ApplicationDocuments.GetDefinition(),
  );

  const [sendToDocusign, { loading: loadingSendToDocusign }] = useMutation(
    Api.ApplicationDocuments.SendToDocusign(),
    {
      onCompleted: () => {
        notification.info({ message: "Successfully submitted the document" });
      },
      onError: (error) => {
        notification.error({ message: error.message });
      },
    },
  );

  if (loading) return <LoadingSpinner />;

  const searchData = documents.filter((doc) =>
    doc.name.toLowerCase().includes(searchText.toLowerCase()),
  );

  const openSubmissionModal = async (document: DeliverableDocumentNode) => {
    await getAnchors({ variables: { documentId: document.id, applicationId: applicationId } });
    setOpenModal(true);
    setSubmissionDocument(document);
  };

  const submitDocument = async ({ documentId, signers, ccs }) => {
    const _signers: DsUserNode[] = signers.map((p: any) => ({
      key: p.key,
      name: p.name,
      email: p.email,
      order: p.order,
    }));

    const _ccs: DsUserNode[] = ccs.map((p: any, index: number) => ({
      key: index,
      name: p.name,
      email: p.email,
    }));

    sendToDocusign({
      variables: {
        applicationId: applicationId,
        documentId: documentId,
        signers: _signers,
        ccs: _ccs,
      },
    }).then(() => setSubmissionDocument(null));
  };

  const columns: ColumnsType<CustomDeliverableDocument> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (name, record, index) => (
        <>
          <Row align={"middle"}>
            <Col flex={1}>
              <Avatar
                style={{ background: avatarColors[index], fontWeight: "bold" }}
                shape="square"
                alt={record.name}
              >
                {createDocAcronym(record.name)}
              </Avatar>
              <span style={{ paddingLeft: 10 }}>{name}</span>
            </Col>
            <Col hidden={!record?.outdated}>
              <Tooltip title="Outdated">
                <Tag color="red">O</Tag>
              </Tooltip>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Form Number",
      dataIndex: "formNumber",
    },
    {
      title: "Updated",
      dataIndex: "revisionDate",
      render: (date) => (
        <div style={{ whiteSpace: "nowrap" }}>
          {date ? new Date(date).toLocaleDateString() : ""}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "5%",
      align: "center",
      render: (_, doc: any) => (
        <Space>
          <Link
            style={{ color: "#FFFFFF" }}
            to={`generate/${doc.documentId}`}
            state={{
              document: doc,
              documentId: doc.documentId,
              id: doc.id,
              applicationId,
            }}
          >
            <Tooltip title="Download">
              <Button type="link" icon={<DownloadOutlined />} />
            </Tooltip>
          </Link>
          <Link
            style={{ color: "#FFFFFF" }}
            to={doc.documentId}
            state={{
              document: doc,
              documentId: doc.documentId,
              id: doc.id,
              applicationId,
            }}
          >
            <Tooltip title="View">
              <Button icon={<EyeOutlined />} type="link" />
            </Tooltip>
          </Link>
          {!doc.outdated && (
            <Tooltip title={doc.identifier !== "itcsc" ? "Not Yet Supported" : "Finalize"}>
              <Button
                danger
                type="link"
                icon={<SendOutlined />}
                onClick={() => openSubmissionModal(doc)}
                loading={doc.id === submissionDocument?.id && loadingSendToDocusign}
                disabled={doc.identifier !== "itcsc" || doc.envelope?.state === "waiting"}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <SmartTable
        data={searchData}
        columns={columns}
        loading={loading}
        rowKey={(doc) => doc.id}
        size="middle"
      />
      {submissionDocument !== null && definition !== undefined ? (
        <SubmissionForm
          open={openModal}
          setOpen={setOpenModal}
          submissionDocument={submissionDocument}
          definition={definition.getApplicationDocumentDefinition}
          onFinish={async ({ values, documentId }) => {
            submitDocument({ ...values, documentId });
          }}
        />
      ) : null}
    </>
  );
};

const avatarColors = [
  "#096dd9",
  "#237804",
  "#722ed1",
  "#006d75",
  "#08979c",
  "#F5222D",
  "#ad4e00",
];

function createDocAcronym(inputString: string): string {
  // Replace hyphens and underscores with spaces
  const words = inputString.replace(/[-_]/g, " ").split(" ");

  // Use regex to extract the first letter from each word
  const acronym = words
    .map((word) => {
      const match = word.match(/^\w/);
      return match ? match[0] : "";
    })
    .join("");

  return acronym.slice(0, 3).toUpperCase();
}

export default DraftApplicationDocuments;
